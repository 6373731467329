import { createStore } from "vuex";
import { locale } from "@/storage";

export default createStore({
  state: {
    accounts: [],
    provider: null,
    isConnected: false,
    rate: 0,
    currentBalance: "",
    locale: locale.get() || '',
    themeMode: '',
    showWallets: false
  },
  mutations: {
    updateAccount(state, value) {
      state.accounts = value;
    },
    updateRate(state, value) {
      state.rate = value;
    },
    updateProvider(state, value) {
      state.provider = value;
    },
    updateIsConnected(state, value) {
      state.isConnected = value;
    },
    updateCurrentBalance(state, value) {
      state.currentBalance = value;
    },
    updateLocale(state, value) {
      state.locale = value;
    },
    updateThemeMode(state, value) {
      state.themeMode = value;
    },
    updateAppModelType(state, value) {
      state.appModelType = value;
    },
    updateShowWallets(state, value) {
      state.showWallets = value;
    }
  },
  actions: {},
  modules: {}
});
