<template>
  <div class="question-box">
  
   <main class="main main--question">
     <div class="question">
		<div class="title">{{$t('Frequently Asked Questions')}}</div>
	 </div>
	 <div class="question-list">
		<div class="item" v-for="(item, index) in list" :key="index">
			 <div class="title flex-between">
				<span>{{ item.title }}</span>
				<img v-if="item.showDesc" @click="item.showDesc = false" src="@/assets/images/close.png" alt="">
				<img v-else @click="item.showDesc = true" src="@/assets/images/add.png" alt="">
			 </div>
			 <div class="desc" v-show="item.showDesc" v-html="item.desc">
			 </div>
		   </div>
	 </div>
   </main>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { getQuestion } from "@/api";
import { useStore } from "vuex";

export default {
  components: {
  },
  setup () {
	const store = useStore()
     const data = reactive({
	   questionData: null,
	   locale: computed(() => store.state.locale),
	   list: computed(() => {
		 if(data.questionData && data.locale) {
			return data.questionData[data.locale]
		 }
		 return null
	   }),
	   toUrl: (link) => {
		 window.open(link)
	   }
     })
	getQuestion().then((res) => {
	  data.questionData = res
	}).catch((err) => {
		
	});
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="scss" scoped>
.question-box{
  padding: 40px 0 50px;
  background: #F4F4F4;
  min-height: 590px;
}
.question{
  max-width: 1200px;
  margin: 0 auto;
  .title{
	text-align: center;
	font-size: 48px;
	color: #111;
	font-family: 'Nyala';
	border-bottom: 1px solid #CCCCCC;
	padding-bottom: 49px;
	
  }
 
}
 .question-list{
	max-width: 1200px;
  margin: 40px auto 0;
 
	.item{
		margin-bottom: 10px;
		  .title{
			background: #fff;
			height: 60px;
			font-size: 24px;
			color: #111;
			font-family: 'Nyala';
			padding: 0 20px;
			 img{
				cursor: pointer;
			}
		  }
		  .desc{
			padding: 20px 40px;
			font-size: 14px;
			color: #666666;
			line-height: 24px;
			word-break: break-all;
			img{
				display: block !important;
				width: 100% !important;
			}
		  }
		}
  }
    @media screen and (max-width: 480px) {
		.question-box{
  padding: 20px 0 30px;
  background: #F4F4F4;
  min-height: 300px;
}
.question{
  max-width: 1200px;
  margin: 0 auto;
  .title{
	text-align: center;
	font-size: 30px;
	color: #111;
	font-family: 'Nyala';
	border-bottom: 1px solid #CCCCCC;
	padding-bottom: 30px;
  }
 
}
 .question-list{
	max-width: 1200px;
  margin: 20px auto 0;
	.item{
		margin-bottom: 6px;
		  .title{
			background: #fff;
			height: 40px;
			font-size: 17px;
			color: #111;
			font-family: 'Nyala';
			padding: 0 20px;
		  }
		  .desc{
			padding: 20px 40px;
			font-size: 14px;
			color: #666666;
			line-height: 24px;
			word-break: break-all;
			img{
				display: block !important;
				width: 100% !important;
			}
		  }
		}
  }
	}
</style>

<style>
.question-list .desc img{
		display: block !important;
				width: 100% !important;
}
</style>
