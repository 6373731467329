<template>
  <div class="facts-about-box" v-if="isShow">
	  <div class="facts-about flex">
		<div class="left">
		  <div class="title">
			{{$t('Facts about DST')}}
		  </div>
		 <div class="btn-box left-btn">
		    <el-button @click="$router.push({name: 'Question'})" type="primary" round="true" size="medium">{{$t('View FAQs here')}}<i class="el-icon-arrow-right el-icon--right"></i></el-button>
	     </div>
		</div>
		<div class="right flex-item">
		   <div class="item" v-for="(item, index) in facts" :key="index">
			 <div class="title flex-between">
				<span>{{ item.title }}</span>
				<img v-if="item.showDesc" @click="item.showDesc = false" src="@/assets/images/close.png" alt="">
				<img v-else @click="item.showDesc = true" src="@/assets/images/add.png" alt="">
			 </div>
			 <div class="desc" v-if="item.showDesc">
				<div class="desc-item" v-for="subItem in item.descList">
					<span>{{ subItem.title }}</span>
					<img v-if="subItem.isDownLoad" @click="saveAsFile(subItem.downUrl)" class="down-icon" src="@/assets/images/down_icon.png" alt="">
					<img v-if="subItem.isUrl" @click="toUrl(subItem.url)" src="@/assets/images/go_icon.png" class="go-icon" alt="">
				</div>
			 </div>
		   </div>
		</div>
	  </div>
   </div>
<footer class="footer">
<div class="container">
   DAO Secret Telegram 2023
</div>
</footer>
</template>

<script>
import { reactive, toRefs, computed, onMounted, watch  } from "vue";
import { getFooter } from "@/api";
import { useStore } from "vuex";
import { saveAsFile } from  "@/utils/saveFile"
import { useRouter } from "vue-router";
export default {
  components: {
  },
  setup () {
	const router = useRouter()
	const store = useStore()
     const data = reactive({
	   footData: null,
	   isShow: true,
	   locale: computed(() => store.state.locale),
	   facts: computed(() => {
		 if(data.footData && data.locale) {
			return data.footData[data.locale]
		 }
		 return null
	   }),
	   toUrl: (link) => {
		 window.open(link)
	   },
	   saveAsFile: (url) => {
		 try {
		  window.open(url)
		 } catch (error) {
	       console.log(error)
		 }
	   }
     })
	getFooter().then((res) => {
	  data.footData = res
	}).catch((err) => {
		
	});
	
    watch(() =>router.currentRoute.value.name,(newValue,oldValue)=> {
        console.log('watch',newValue);
		if(newValue == 'Location') {
		data.isShow = false
		} else {
		data.isShow = true
		}
},{ immediate: true })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    max-width: 1200px;
    height: 50px;
    margin: 0 auto;
	line-height: 50px;
	color: #919199;
	font-size: 14px;
	text-align: center;
}
.footer {
	position:relative;
	background:#000;
}

 .facts-about-box{
   background: #F4F4F4;
  
   .facts-about{
     padding: 60px 0;
     max-width: 1200px;
     margin: 0 auto;
	 .left{
		text-align: center;
		margin-right: 60px;
		.title{
		  color: #111111;
		  font-size: 30px;
		  font-family: 'Barlow-Bold';
		  margin-bottom: 20px;
		}
	 }
	 .right{
		.item{
			margin-bottom: 10px;
		  .title{
			background: #fff;
			height: 60px;
			font-size: 24px;
			color: #111;
			font-family: 'Nyala';
			padding: 0 20px;
			img{
			  cursor: pointer;
			}
		  }
		  .desc{
			padding-left: 90px;
			.desc-item{
			  line-height: 18px;
			  color: #666;
			  font-size: 18px;
			  margin: 18px;
			  img{
				width: 14px;
				height: 18px;
				margin-left: 10px;
				cursor: pointer;
			  }
			  .go-icon{
				display: inline-block;
				width: 14px;
				height: 14px;
			  }
			}
		  }
		}
	 }
   }
 }
.btn-box .el-button{
  width: 360px;
  height: 66px;
  border-radius: 33px;
  font-size: 22px;
  font-family: 'Barlow-Bold';
  .box{
	justify-content: center;
  }
  img{
	width: 30px;
	margin-right: 10px;
  }
}
.left-btn{
  .el-button{
	width: 300px;
  }
}
  @media screen and (max-width: 480px) {
	 .facts-about-box{
   background: #F4F4F4;
  
   .facts-about{
     padding: 30px 0;
     max-width: 1200px;
     margin: 0 auto;
	 flex-wrap: wrap;
	 width: 100%;
	 .left{
		text-align: center;
		margin-right: 0px;
		width: 100%;
		margin-bottom: 30px;
		.title{
		  color: #111111;
		  font-size: 30px;
		  font-family: 'Barlow-Bold';
		  margin-bottom: 20px;
		}
	 }
	 .right{
		width: 100%;
		flex: unset;
		.item{
		   margin-bottom: 6px;
		  .title{
			background: #fff;
			height: 44px;
			font-size: 17px;
			color: #111;
			font-family: 'Nyala';
			padding: 0 15px;
		  }
		  .desc{
			padding-left: 40px;
			.desc-item{
			  line-height: 14px;
			  color: #666;
			  font-size: 14px;
			  margin: 12px;
			  img{
				width: 10px;
				height: 14px;
				margin-left: 10px;
			  }
			}
		  }
		}
	 }
   }
 }
 .btn-box .el-button{
  width: 180px;
  height: 50px;
  border-radius: 33px;
  font-size: 16px;
  font-family: 'Barlow-Bold';
  line-height: 30px;
  .box{
	justify-content: center;
  }
  img{
	width: 18px;
	margin-right: 10px;
  }
}
  }
  
</style>