import http from "../utils/http";
export function getFooter() {
  return http.get(window.location.pathname + "config/footer.json");
}
export function getHeader() {
  return http.get(window.location.pathname + "config/header.json");
}
export function getDownload() {
  return http.get(window.location.pathname + "config/download.json");
}
export function getQuestion() {
  return http.get(window.location.pathname + "config/question.json");
}