<template>
  <div class="download-box">
  
   <main class="main main--download">
     <div class="download">
		<div class="title">{{$t('Gateway download')}}</div>
		<div class="sub-title">{{$t('This interface is the only source for the DST gateway node installer.')}}<br> 
{{$t('Do not trust downloads from other sources.')}}</div>
       <div class="desc">
		{{$t('The DST client can be used as a communication gateway or as a full node authenticator.')}}<br> 
{{$t("I don't know where to start?")}}<br> 
{{$t('Detailed installation instructions and source code can be found on Github.')}}<br> 
{{$t('Note: Windows installation does not support Windows 7')}}
	   </div>
	   <div class="install-box" v-if="curent.length">
		  <div class="item flex-middle" v-for="item in curent">
             <el-button class="install-btn" @click="saveAsFile(item.downUrl)" type="primary" size="medium">{{item.type}}<i class="el-icon-download el-icon--right"></i></el-button>
		    <div class="center flex-item">
				<div class="center-title">{{$t('Version')}} {{item.version}} 丨 {{item.size}}</div>
				<div class="center-desc" v-if="item.type == 'Windows'">{{$t('Windows 7 is not supported')}}</div>
			</div>
			<div class="right">
			  <div class="right-title">{{$t('SHA256 checksum')}}</div>
				<div class="right-desc">{{item.sha256}}</div>
			</div>
		  </div>
	   </div>
	   <div class="verify-box">
		  <span @click="$router.push({name: 'Verify'})">{{$t('How to verify checksums？')}}</span>
	   </div>
	   <div class="title">{{$t('Main chain download')}}</div>
		<div class="sub-title">{{$t('This interface is the only source for the DST main chain installer.')}}<br> 
{{$t('Do not trust downloads from other sources.')}}</div>
       <div class="desc">
		<!-- {{$t('The DST client can be used as a communication gateway or as a full node authenticator.')}}<br>  -->
{{$t("I don't know where to start?")}}<br> 
{{$t('Detailed installation instructions and source code can be found on Github.')}}<br> 
<!-- {{$t('Note: Windows installation does not support Windows 7')}} -->
	   </div>
	   <div class="install-box" v-if="chainCurrent.length">
		  <div class="item flex-middle" v-for="item in chainCurrent">
             <el-button class="install-btn" @click="saveAsFile(item.downUrl)" type="primary" size="medium">{{item.type}}<i class="el-icon-download el-icon--right"></i></el-button>
		    <div class="center flex-item">
				<div class="center-title">{{$t('Version')}} {{item.version}} 丨 {{item.size}}</div>
				<!-- <div class="center-desc" v-if="item.type == 'Windows'">{{$t('Windows 7 is not supported')}}</div> -->
			</div>
			<div class="right">
			  <div class="right-title">{{$t('SHA256 checksum')}}</div>
				<div class="right-desc">{{item.sha256}}</div>
			</div>
		  </div>
	   </div>
	   <div class="verify-box">
		  <span @click="$router.push({name: 'Verify'})">{{$t('How to verify checksums？')}}</span>
	   </div>
	   <div class="release-box">
		 <div class="release-title">{{$t('Release Notes')}}</div>
		 <div class="release-list flex-between" v-if="history.length">
			<div class="item" v-for="item in history" @click="toUrl(item.downUrl)">
				<div class="note-title">
                    DST <span>Blockchain {{item.version}}</span>
				</div>
				<div class="note-desc">
				   {{item.time}}
				</div>
			</div>
		 </div>
	   </div>
	 </div>
   </main>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { getDownload } from "@/api";
import { saveAsFile } from  "@/utils/saveFile"

export default {
  components: {
  },
  setup () {
     const data = reactive({
	   curent: [],
	   chainCurrent: [],
	   history: [],
	   toUrl: (link) => {
		console.log(link, 'link')
		window.open(link)
	   },
	   saveAsFile: (url) => {
		 try {
		//    saveAsFile(url)
		   window.open(url)
		 } catch (error) {
	       console.log(error)
		 }
	   }
     })
	 onMounted(() => {
	  console.log('222222222222222222222222222')
	  getDownload().then((res) => {
		data.curent = res.current
		data.history = res.history
		data.chainCurrent = res.chainCurrent
	 }).catch((err) => {
		console.log(err)
	 });  
	 })

    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="scss" scoped>
.download{
  max-width: 1200px;
  padding: 62px 0;
  margin: 0 auto;
  background: url('~@/assets/images/download_bg.png') center bottom;
  background-size: cover;
  .title{
	text-align: center;
	font-size: 48px;
	color: #111;
	font-family: 'Nyala';
	margin-bottom: 45px;
  }
  .sub-title{
	font-size: 32px;
	color: #666;
	line-height: 42px;
	font-family: 'Barlow-Medium';
	margin-bottom: 30px;
  }
  .desc{
	font-size: 19px;
	color: #666;
	line-height: 29px;
	font-family: 'Barlow-Regular';
  }
  .install-box{
	// padding: 30px 0;
	.item{
		margin: 60px 0;
	}
	.install-btn{
	  width: 300px;
	  height: 60px;
	  font-size: 22px;
      font-family: 'Barlow-Bold';
	}
	.center{
	  margin-left: 40px;
	  .center-title{
		font-size: 24px;
		color: #111;
		font-family: 'Barlow-Medium';
	  }
	  .center-desc{
		font-size: 16px;
		color: #999999;
		font-family: 'Barlow-Regular';
	  }
	}
	.right{
	  .right-title{
		font-size: 20px;
		color: #666;
		font-family: 'Barlow-Medium';
	  }
	  .right-desc{
		font-size: 16px;
		color: #FF9900;
		font-family: 'Barlow-Medium';
	  }
	}
  }
  .verify-box{
	padding: 20px;
	text-align: right;
	color: #00AAFF;
	font-family: 'Barlow-Bold';
	border-top: 1px solid #E6E6E6;
	margin-bottom: 120px;
	cursor: pointer;
  }
.release-box{
  .release-title{
	   font-size: 48px;
	   color: #111111;
	   font-family: 'Nyala';
	   text-align: center;
	   margin-bottom: 20px;
	}
  .release-list{
	flex-wrap: wrap;
	.item{
	  width: 360px;
	  margin: 20px 0;
	  box-sizing: border-box;
	  padding: 24px 40px;
	  background: #F4F4F4;
	  border: 1px solid #E6E6E6;
	  cursor: pointer;
	  .note-title{
		 font-size: 20px;
	   color: #111111;
	   font-family: 'Barlow-Medium';
	    span{
		  color: #00AAFF;
		  font-size: 20px;
		}
	  }
	  .note-desc{
		 font-size: 20px;
	    color: #999;
	    font-family: 'Barlow-Regular';
	  }
	}
  }
 }
}

 @media screen and (max-width: 480px) {
	.download{
  max-width: 1200px;
  padding: 32px 15px;
  margin: 0 auto;
  background: url('~@/assets/images/download_bg.png') center bottom;
  background-size: cover;
  .title{
	text-align: center;
	font-size: 48px;
	color: #111;
	font-family: 'Nyala';
	margin-bottom: 28px;
  }
  .sub-title{
	font-size: 18px;
	color: #666;
	line-height: 28px;
	font-family: 'Barlow-Medium';
	margin-bottom: 30px;
  }
  .desc{
	font-size: 15px;
	color: #666;
	line-height: 25px;
	font-family: 'Barlow-Regular';
  }
  .install-box{
	// padding: 30px 0;
	.item{
		margin: 30px 0;
		flex-wrap: wrap;
	}
	.install-btn{
	  width: 140px;
	  height: 40px;
	  font-size: 16px;
      font-family: 'Barlow-Bold';
	}
	.center{
	  margin-left: 20px;
	  .center-title{
		font-size: 18px;
		color: #111;
		font-family: 'Barlow-Medium';
	  }
	  .center-desc{
		font-size: 12px;
		color: #999999;
		font-family: 'Barlow-Regular';
	  }
	}
	.right{
		margin-top: 15px;
	  .right-title{
		font-size: 18px;
		color: #666;
		font-family: 'Barlow-Medium';
	  }
	  .right-desc{
		font-size: 16px;
		color: #FF9900;
		font-family: 'Barlow-Medium';
		word-break: break-all;
	  }
	}
  }
  .verify-box{
	padding: 20px;
	text-align: right;
	color: #00AAFF;
	font-family: 'Barlow-Bold';
	border-top: 1px solid #E6E6E6;
	margin-bottom: 40px;
  }
.release-box{
  .release-title{
	   font-size: 48px;
	   color: #111111;
	   font-family: 'Nyala';
	   text-align: center;
	   margin-bottom: 20px;
	}
  .release-list{
	flex-wrap: wrap;
	.item{
	  width: 100%;
	  margin: 10px 0;
	  box-sizing: border-box;
	  padding: 15px 20px;
	  background: #F4F4F4;
	  border: 1px solid #E6E6E6;
	  .note-title{
		 font-size: 18px;
	   color: #111111;
	   font-family: 'Barlow-Medium';
	    span{
		  color: #00AAFF;
		  font-size: 18px;
		}
	  }
	  .note-desc{
		 font-size: 16px;
	    color: #999;
	    font-family: 'Barlow-Regular';
	  }
	}
  }
 }
}
 }
</style>
