import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../views/index";
import Download from "../views/download";
import Verify from "../views/verify";
import Question from "../views/question";
import Location from "../views/location";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
  },
  {
    path: "/download",
    name: "Download",
    component: Download
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify
  },
  {
    path: "/question",
    name: "Question",
    component: Question
  },
  {
    path: "/location",
    name: "Location",
    component: Location
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
