<template>
  <div class="header-box">
 
    <el-row class="header">
      <el-col :lg="21" :xs="9"
        ><div class="grid-content flex-middle">
          <div class="img-box flex-middle" @click="$router.push({name: 'Index'})">
              <img src="~@/assets/images/logo.png" alt="">
          </div>
          
          <el-menu
            router
            :default-active="$route.path"
            @select="handleSelect"
            class="el-menu-demo"
            :class="{'mobileMenu-active': mobileShowMenu}"
            mode="horizontal"
            background-color="transparent"
            text-color="#666"
            active-text-color="#e7973e"
          >
            <el-menu-item index="/">
              <span class="item-name">{{$t('Recruit')}}</span>
              <i class="el-icon-right"></i>
            </el-menu-item>
            <el-menu-item index="./">
              <span style="display:block" @click.stop="toUrl(headerData.faucetUrl)">
                 <span class="item-name">{{$t('Faucet')}}</span>
              <i class="el-icon-right"></i>
              </span>
             
            </el-menu-item>
           
            <!-- <el-menu-item index="./">
              <span @click.stop="" style="display:block">
              <span class="item-name">{{$t('Ecosystem')}}</span>
              <i class="el-icon-right"></i>
             </span>
            </el-menu-item> -->
            <!-- <el-menu-item index="/3">
              <span class="item-name">DAO</span>
              <i class="el-icon-right"></i>
            </el-menu-item> -->
            <el-menu-item index="./">
              <span style="display:block" @click.stop="toUrl(headerData.whitePapeUrl)">
              <span class="item-name">{{$t('White Paper')}}</span>
              <i class="el-icon-right"></i>
              </span>
            </el-menu-item>
              <el-menu-item index="/download">
              <span class="item-name">{{$t('Download')}}</span>
              <i class="el-icon-right"></i>
            </el-menu-item>
            <el-menu-item index="./">
              <span style="display:block" @click.stop="toUrl(headerData.documentUrl)">
                 <span class="item-name">{{$t('Document')}}</span>
              <i class="el-icon-right"></i>
              </span>
             
            </el-menu-item>
          </el-menu>
        </div></el-col>
        <el-col :lg="3" :xs="15">
          <div class="header-left language flex-between">
              <img @click="toUrl('https://twitter.com/freemas90472513')" src="~@/assets/images/header_icon2.png" alt="">
              <img @click="toUrl('https://github.com/daodst')" src="~@/assets/images/header_icon3.png" alt="">
              <a href="mailto://fm2022coin@proton.me">
                <img src="~@/assets/images/header_icon4.png" alt="">
              </a>
              <img @click="toUrl('https://www.facebook.com/groups/freemasonrycc')" src="~@/assets/images/fb.png" alt="">
        
             <img @click="changeLanuage('en')" v-if="locale == 'zh-cn'" src="~@/assets/images/en.png" alt="">
             <img @click="changeLanuage('zh-cn')"  v-else src="~@/assets/images/zh_cn.png" alt="">

              <div class="mobile-menu">
              <i @click="mobileShowMenu = !mobileShowMenu" class="el-icon-more" :class="{'active': mobileShowMenu}"></i>
            </div>
          </div>

        </el-col>
    </el-row>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { setLang } from "@/utils/i18n"
import { useStore } from "vuex";
import { getHeader } from "@/api"

export default {
  components: {
  },
  setup() {
    const store = useStore()
    const data = reactive({
      locale: computed(() => store.state.locale),
      mobileShowMenu: false,
      headerData: null,
      changeLanuage: (val) => {
        setLang(val)
        data.local = val
      },
       toUrl: (link) => {
		   window.open(link)
	   },
    });
    getHeader().then((res) => {
      data.headerData = res
    }).catch((err) => {
      
    }); 
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss">
.header{
  max-width: 1400px;
  margin: 0 auto;
}
.language{
  justify-content: flex-end;
  .el-dropdown{
    color: #75809F;
    font-size: 16px;
  }
  .mobile-language {
    display: none;
  }
  .mobile-menu {
    display: none;
    padding-right: 20px !important;
  }
}
.el-dropdown-menu__item.active{
  color: #0264C3;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: rgba($color: #0264C3, $alpha: 0.1) !important;
    color: #0264C3 !important;
}
.theme-light {
  .language{
    .el-dropdown{
      color: #333;
    }
  }
  .mobile-menu {
    .nav-icon {
      background-color: #333;
      &:before,
      &:after {
        background:  #333;
      }
    }
  }
}
@media screen and (max-width: 480px) {
 .language {
    .el-dropdown{
      font-size: 18px;
    }
    .pc-language {
      display: none;
    }
    .mobile-language {
      display: block;
    }
    .mobile-menu {
      display: block;
    }
  }
}
.el-dropdown-link{
    background: #272727;
    padding: 8px;
    border-radius: 50%;
    margin-right: 30px;
  img{
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50%;
  }
}
@media screen and (max-width: 480px) {
.el-dropdown-link{
    background: #272727;
    padding: 8px;
    border-radius: 50%;
    margin-right: 5px;
  img{
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50%;
  }
}
}
</style>

<style lang="scss" scoped>

.header-box {
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(204,204,204,0.2);
  .grid-content {
    height: 75px;
    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }
    .el-menu.el-menu--horizontal {
      border: 0;
    }
    .el-menu--horizontal>.el-menu-item {
      border: 0 !important;
    }
    .el-menu-item.is-disabled {
      opacity: 1;
      color: #fff !important;
    }
    .el-menu--horizontal>.el-menu-item a.href-a {
      color: #fff !important;
    }
    .img-box {
      height: 41px;
      line-height: 41px;
      white-space: nowrap;
      padding: 0 300px 0 0;
      font-size: 22px;
      letter-spacing: 1px;
      color: #fff;
      font-weight: bold;
      img {
        height: 50px;
        margin-right: 18px;
      }
    }
  }
  .el-submenu__title:hover{
    background-color: transparent !important;
  }
  .el-menu-item:hover{
    background-color: transparent !important;
  }
  .el-menu-item {
    margin: 0 15px;
    padding: 0;
    font-size: 16px;
  }
  
  .handle-btns {
    height: 100%;
    justify-content: flex-end;
    color: #fff;
    padding-right: 48px;
    .btn,
    .setting,
    .more {
      height: 48px;
      margin-left: 20px;
      line-height: 48px;
      font-size: 20px;
    }
    .btn {
      background: #188277;
      padding: 0 10px;
      white-space: nowrap;
    }
    .setting,
    .more {
      width: 60px;
      border: 1px solid #08E8CE;
      text-align: center;
      img {
        width: 32px;
      }
    }
  }
  .theme-btn {
    color: #fff;
    margin: 0 0 0 15px;
    cursor: pointer;
    user-select: none;
    width: 36px;
    height: 35px;
    border: none;
    margin: 0;
    padding: 0;
    background-color: #40444F;
    margin-left: 8px;
    border-radius: 8px;
    svg {
      stroke: #08e8ce;
    }
    &:hover {
      background-color: #565A69;
    }
  }
}
.theme-light {
  .header-box {
    background: #c9e1c9;
    .grid-content {
      .el-menu--horizontal>.el-menu-item,
      .el-menu--horizontal>.el-menu-item a {
        color: #333333 !important;
      }
      .el-menu--horizontal>.el-menu-item.is-active,
      .el-menu--horizontal>.el-menu-item.is-active a {
        color: #3A7343 !important;
      }
      .el-menu--horizontal>.el-menu-item.is-disabled,
      .el-menu--horizontal>.el-menu-item.is-disabled a {
        color: #333333 !important;
      }
      .el-menu--horizontal>.el-menu-item a.href-a {
        color: #333333 !important;
      }
      .img-box {
        color: #333333;
      }
    }
    .theme-btn {
      background: #EDEEF2;
      svg {
        stroke: #3A7343;
      }
      &:hover {
        background-color: #CED0D9;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .header-box {
    .grid-content {
      .img-box {
        padding: 0 12px;
      }
      .el-menu-item {
        font-size: 16px;
      }
      .el-menu-demo {
        display: none;
        position: fixed;
        top: 75px;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0,0,0,.8) !important;
        z-index: 500;
        .el-menu-item {
          float: none;
        }
        &.mobileMenu-active {
          display: block;
          text-align: center;
        }
      }
    }
  }
  .theme-light {
    .header-box {
      .grid-content {
        .el-menu--horizontal>.el-menu-item,
        .el-menu--horizontal>.el-menu-item a {
          color: #DFE0E2 !important;
        }
        .el-menu--horizontal>.el-menu-item.is-active,
        .el-menu--horizontal>.el-menu-item.is-active a {
          color: #02EAD0 !important;
        }
        .el-menu--horizontal>.el-menu-item.is-disabled,
        .el-menu--horizontal>.el-menu-item.is-disabled a {
          color: #DFE0E2 !important;
        }
        .el-menu--horizontal > .el-menu-item a.href-a {
          color: #DFE0E2 !important;
          font-weight: normal;
        }
      }
    }
  }
}
.btn-biege {
	background:#2676ff;
	box-shadow: 0 0 8px 1px #2676ff!important;
	color:#fff
}
.btn {
	display:-webkit-inline-flex;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-justify-content:center;
	-ms-flex-pack:center;
	justify-content:center;
	-webkit-align-items:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-flex-shrink:0;
	-ms-flex-negative:0;
	flex-shrink:0;
	height:36px;
  padding: 0 15px;
	background-color:#2676ff;
	font-family:Mukta-Bold;
	font-size:14px;
	line-height:1;
	color:#fff;
  min-width: 120px;
	border-radius:8px;
	transition:all .3s ease;
	outline:0;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
	cursor:pointer
}
.pc-button{
    display: inline-flex;
  }
  .mobile-button{
    display: none;
  }
@media screen and (max-width: 480px) {
  .pc-button{
    display: none;
  }
  .mobile-button{
    display: inline-flex;
  }
}
.item-name{
  font-size: 15px;
  margin-right: 5px;
}
.el-menu-item .el-icon-right{
  font-size: 12px;
}

.header-left{
  height: 100%;
  img{
    width: 18px;
    display: block;
    margin: 0;
    cursor: pointer;
  }
}


</style>
