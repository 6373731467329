import { createI18n } from "vue-i18n"
import { locale } from "../storage"
import store from "../store"
import enLocale from 'element-plus/lib/locale/lang/en'
import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
import zh_cn from "../locales/zh-cn.json"
import en from "../locales/en.json"
export const i18n = createI18n({
  locale: 'en',
  messages: {
    'zh-cn': {
      ...zh_cn,
      ...zhLocale
    },
    'en': {
      ...en,
      ...enLocale
    }
  }
})

export const setLang = (lang) => {
  i18n.global.locale = lang
  store.commit('updateLocale', lang)
  locale.set(lang)
}

export const getLang = () => {
  return i18n && i18n.global.locale
}
