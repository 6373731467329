import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import { locale, themeMode } from "./storage"
import dateFormat from "date-format";
import { i18n, setLang } from "./utils/i18n"

const lang = (locale.get() || 'en').toLowerCase()
setLang(lang)

// 默认 黑色
const theme = themeMode.get() || 'light'
themeMode.set(theme)
store.commit('updateThemeMode', theme)

let queryParse = (str) => {
  if (!str || str === '0') {
    return {}
  }
  let dataArr = decodeURIComponent(str).split('&')
  let params = {}
  dataArr.forEach(query => {
    let queryItem = query.split('=')
    if (queryItem.length === 1) {
      params.id = queryItem[0]
    } else {
      params[queryItem[0]] = queryItem[1]
    }
  })
  return params
}

let href = window.location.href.replace(/#/g, '')
if (href.includes('?')) {
  let search = href.slice(href.indexOf('?') + 1, href.length)
  let params = queryParse(search)
  
  if (params.theme) {
    themeMode.set(params.theme)
    store.commit('updateThemeMode', params.theme)
  }
}

const app = createApp(App);
app.config.globalProperties.dateFormat = dateFormat;
app
  .use(ElementPlus)
  .use(store)
  .use(i18n)
  .use(router)
  .mount("#app");
