<template>
  <el-container>
    <el-header>
      <Header />
    </el-header>
    <el-main>
      <router-view />
      <Footer />
    </el-main>
  </el-container>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
// import web3Data from "@/utils/initWeb3";
export default {
  components: {
    Header,
    Footer
  },
  setup () {
    // web3Data.initWeb3()
  }
};
</script>
<style lang="scss">
@import "./assets/style/font.scss";
@import "./assets/style/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .el-container{
    padding-top: 75px;
    display: block;
  }
  .el-header {
    padding: 0;
    height: 75px !important;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 500;
  }
  .el-main {
    padding: 0;
    overflow: unset;
  }
}
.btn-biege {
	background:#2676ff;
	box-shadow: 0 0 8px 1px #2676ff!important;
	color:#fff
}
.btn {
	display:-webkit-inline-flex;
	display:-ms-inline-flexbox;
	display:inline-flex;
	-webkit-justify-content:center;
	-ms-flex-pack:center;
	justify-content:center;
	-webkit-align-items:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-flex-shrink:0;
	-ms-flex-negative:0;
	flex-shrink:0;
	height:36px;
  padding: 0 15px;
	background-color:#2676ff;
	font-family:Mukta-Bold;
	font-size:14px;
	line-height:1;
	color:#fff;
  min-width: 120px;
	border-radius:8px;
	transition:all .3s ease;
	outline:0;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
	cursor:pointer
}
</style>
