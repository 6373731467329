<template>
  <div class="location-box">
  
   <main class="main main--location">
     <div class="location">
      <div class="top flex-middle">
        <img class="icon" src="@/assets/images/homescreen.png" alt="">
        <div class="info">
          <div class="title">Secret Telegram</div>
          <div class="desc flex-middle">
            <img src="@/assets/images/android.png" alt="">
            <span>{{ $t('Suitable for Android devices') }}</span>
          </div>
        </div>
      </div>
      <div class="size-info flex-between">
        <span>{{ $t('Version')}}： {{ version }}</span>
        <span>{{ $t('Size') }}： {{ Number(size/1024/1024).toFixed(1) }}M</span>
      </div>
      <div class="download">
        <div class="title">{{ $t('Download Url') }}：</div>
        <div class="url-box">
          {{url}}
        </div>
        <div class="tip">{{ $t('Tip') }}</div>
        <div class="btn-box flex-between">
           <el-button block type="primary" style="width: 48%" round="true" size="large" @click="toDownload">{{ $t('Start downloading') }}</el-button>
           <el-button block type="primary" style="width: 48%" plain round="true" size="large" class="clip-button" :data-clipboard-text="url">{{ $t('Copy link') }}</el-button>
        </div>
      </div>
     </div>
   </main>
  </div>
  <div class="wechat-mask" v-if="showMask" @click="showMask = false">
    <img src="@/assets/images/wechatShare.png" alt="">
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import Clipboard from "clipboard"
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";

export default {
  components: {
  },
  setup () {
    const route = useRoute()
    const { t } = useI18n()
     const data = reactive({
      url: route.query.url || '',
      version: route.query.version || '',
      size: route.query.size || 0,
      showMask: false,
	    toUrl: (link) => {
		    window.open(link, '_blank')
	    },
      toDownload: () => {
        if (data.isWechat()) {
          data.showMask = true
        } else {
          data.toUrl(data.url)
        }
      },
      download: (url) => {
        if (!url) {
          return;
        }
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "daodst.apk");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      isWechat: () => {
        let ua = window.navigator.userAgent.toLowerCase()
        return /micromessenger/.test(ua);
      }
     })
      var clipboard = new Clipboard('.clip-button')
      clipboard.on('success', function (e) {
        ElMessage.success(t('copy successfully'))
        e.clearSelection()
      })
      clipboard.on('error', function () {
        ElMessage.error(t('Copy failed, please manually long press to copy'))
      }) 
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="scss" scoped>
.tip{
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
}
.wechat-mask{
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  img{
    display: block;
    width: 100%;
  }
}
.location-box{
  padding: 20px 15px 20px;
  background: #F4F4F4;
  min-height: calc(100vh - 125px);
}
.location{
  background: #fff;
  padding: 30px 15px;
  border-radius: 12px;
  max-width: 640px;
   margin: 0 auto;
}
.top{
  margin-bottom: 20px;
  .icon{
    width: 80px;
    border-radius: 12px;
    margin-right: 15px;
  }
  .info{
    .title{
      font-size: 20px;
      color: #333;
      font-weight: 700;
      margin-bottom: 6px;
      padding-left: 5px;
    }
    .desc{
      font-size: 14px;
    }
  }
}
.size-info{
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
}
.download{
  .title{
     font-size: 14px;
      color: #333;
      font-weight: 700;
      margin-bottom: 10px;
  }
  .url-box{
    word-break: break-all;
    margin-bottom: 30px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    background: rgba(0,0,0, 0.05);
    border-radius: 10px;
  }
}
</style>

