<template>
  <div class="index-box">
  
   <main class="main main--home">
     <div class="banner">
      <!-- <img class="banner-img" src="~@/assets/images/banner.png" alt=""> -->
       <div class="banner-text">
          <div class="title">{{ $t('Welcome to the DST Enthusiast Community') }}</div>
          <div class="desc">{{ $t('Decentralized secret telegram cluster,')}} <br>{{ $t('next-generation web3 products and services.')}}</div>
       </div>
    </div>
	<div class="global-secction">
       <div class="global-info">
	  <div class="title">{{ $t('Global nodes recruit volunteers')}}</div>
	  <div class="desc">
		<!-- {{ $t('DST is a self-made public chain, which conducts global super node recruitment based on the principle of complete decentralization.')}}<br><br>
        {{ $t('Nodes only need to download the program in the open source community, and then stably run to obtain a white list of Genesis donations to participate in the donation contract. The top 21 nodes with stable operation can obtain DSTs to participate in Genesis mining at a price of 0.02, and the DSTs of Genesis will automatically be converted into hash computing power.')}}<br><br>

         {{ $t('The USDT donated by Genesis is is shared by all DST communities, all of which are used to create DST addresses, and the LP will break into the black hole and be directly destroyed on the chain.')}}
	   -->
	   {{ $t("nodeDesc1") }}<br><br>
	   {{ $t("nodeDesc2") }}<br><br>
	   {{ $t("nodeDesc3") }}<br><br>
	   {{ $t("nodeDesc4") }}
	  </div>
	  <div class="btn-box">
		  <el-button type="primary" round="true" size="medium">{{ $t('Join Now')}}</el-button>
	  </div>
	</div>
	</div>
	
	<div class="telegram-box">
		<div class="telegram">
		  <div class="desc-title">{{ $t('DECENTRALIZATION')}}</div>
		  <div class="title">{{ $t('SECRET TELEGRAM')}}</div>
		  <div class="desc">{{ $t('Encrypted communication+payment.')}}<br>
{{ $t('Web 3.0 infrastructure, technical support, strong consensus,')}}<br> 
{{ $t('full destruction, market demand, and output.')}}</div>
		</div>
	</div>
    
	<div class="dao-box">
		<div class="dao">
		  <div class="title">DAO</div>
		  <div class="desc">{{ $t('Decentralized autonomous organization,')}} <br>
{{ $t('voting, managing all consensus and proposals in the community.')}}</div>
		</div>
	</div>

		<div class="evmos-box">
		<div class="evmos">
		  <div class="desc-title">WIDTH</div>
		  <div class="title">EVM</div>
		  <div class="desc">{{ $t('Compatible with Ethereum contracts,')}}<br>
{{ $t('with lower GAS costs, faster response times, higher encryption security,')}}<br>
{{ $t('and a more flexible development framework.')}}</div>
		</div>
	</div>
   	<div class="did-box">
		<div class="did">
		  <div class="title">DID</div>
		  <div class="desc">{{ $t('Fully anonymous blockchain network ID number number.')}}</div>
		</div>
	</div>
	<div class="route-box">
		<div class="route">
		  <div class="title">{{ $t('Route Map') }}</div>
		  <div class="img-box">
			<img v-if="locale =='en'" src="@/assets/images/route_en.png" alt="">
			<img v-else src="@/assets/images/route.png" alt="">
		  </div>
		</div>
	</div>
	<div class="multi-box">
		<div class="multi">
			<div class="title">{{ $t('Multi Chain Comparison')}}</div>
			<div class="row-box">
			<div class="row flex-middle">
			  <div class="flex-item">{{ $t('Chain Name')}}</div>
			  <div class="flex-item">{{ $t('Common View')}}</div>
			  <div class="flex-item">{{ $t('Performance Tps')}}</div>
			  <div class="flex-item">{{ $t('Block out Time')}}</div>
			  <div class="flex-item">{{ $t('Transaction Cost')}}</div>
			</div>
	
			<div class="row flex-middle row-active">
				<div class="flex-item">DST</div>
				<div class="flex-item">Dpos</div>
				<div class="flex-item">10000</div>
				<div class="flex-item">6s</div>
				<div class="flex-item">{{ $t('Extremely low')}} (0.001USD)</div>
			</div>
			<div class="row flex-middle">
				<div class="flex-item">ETH</div>
				<div class="flex-item">Pos</div>
				<div class="flex-item">30</div>
				<div class="flex-item">12s</div>
				<div class="flex-item">{{ $t('High')}} (2.5USD)</div>
			</div>
			<div class="row flex-middle">
				<div class="flex-item">BSC</div>
				<div class="flex-item">PosA</div>
				<div class="flex-item">300</div>
				<div class="flex-item">3s</div>
				<div class="flex-item">{{ $t('Low')}} (0.06USD)</div>
			</div>
		 </div>
		</div>
	</div>
    
	<div class="contribute-box">
		<div class="contribute">
		   <div class="title">{{ $t('Contribute To DAODST.com')}}</div>
		   <div class="desc">{{ $t('DST is open source with hundreds of community contributors.')}}<br>
{{ $t('You can propose edits to any of the content on the DST, suggest awesome new features, or help us squash bugs.')}}</div>
		
	  <div class="btn-box">
		  <el-button type="primary" round="true" size="medium" @click="toUrl('https://github.com/daodst')">
			<div class="box flex-middle">
				<img src="@/assets/images/github_icon.png" alt="">
			<span>GitHub</span>
			</div>
		  </el-button>
	  </div>
		</div>
	</div>
   

   </main>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
export default {
  components: {
  },
  setup () {
	 const store = useStore()
     const data = reactive({
	   locale: computed(() => store.state.locale),
	   toUrl: (link) => {
		window.open(link)
	   }
     })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="scss" scoped>
.banner{
   width: 100%;
   background: url('~@/assets/images/banner.png') center center #fff;
   background-size: cover;
   padding-bottom: 40px;
   .banner-img{
	//  position: relative;
	 display: block;
	//  width: 1920px;
	//  left: 50%;
	//  margin-left: -960px;
   }
}
.banner-text{
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 175px 0 680px;
  
  .title{
	color: #002233;
	font-size: 80px;
	font-family: 'Nyala';
  }
  .desc{
	font-size: 26px;
	color: #676767;
	font-family: 'Barlow-Medium';
	line-height: 36px;
  }
}

.global-secction{

}
.global-info{
  max-width: 1200px;
  
  padding: 60px 30px;
  margin: 0 auto;
  margin-top: -500px;
  background: #fff;

  text-align: center;
  .title{
	font-size: 48px;
	font-family: 'Nyala';
	color: #111;
	margin-bottom: 10px;
  }
  .desc{
	color: #666;
	font-size: 22px;
	line-height: 32px;
	font-family: 'Barlow-Regular';
	  margin-bottom: 46px;
  }
}

.btn-box .el-button{
  width: 360px;
  height: 66px;
  border-radius: 33px;
  font-size: 22px;
  font-family: 'Barlow-Bold';
  .box{
	justify-content: center;
  }
  img{
	width: 30px;
	margin-right: 10px;
  }
}
.left-btn{
  .el-button{
	width: 300px;
  }
}
.telegram-box{
  background: url('~@/assets/images/telegram_bg.png') center center;
  background-size: cover;
  .telegram{
	text-align: center;
	padding: 190px 0;
	max-width: 1200px;
	margin: 0 auto;
	.desc-title{
	  color: #111;
	  font-size: 20px;
	   font-family: 'Barlow-Bold';
	}
	.title{
	  font-size: 48px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 20px;
	  line-height: 30px;
	   font-family: 'Barlow-Medium';
	}
  }
}
.dao-box{
  background: url('~@/assets/images/dao_bg.png') center center;
  background-size: cover;
  .dao{
	text-align: center;
	padding: 190px 0;
	max-width: 1200px;
	margin: 0 auto;
	.title{
	  font-size: 48px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 20px;
	  line-height: 30px;
	   font-family: 'Barlow-Medium';
	}
  }
}
.evmos-box{
  background: url('~@/assets/images/evmos_bg.png') center center;
  background-size: cover;
  .evmos{
	text-align: center;
	padding: 190px 0;
	max-width: 1200px;
	margin: 0 auto;
	.desc-title{
	  color: #111;
	  font-size: 20px;
	   font-family: 'Barlow-Bold';
	}
	.title{
	  font-size: 48px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 20px;
	  line-height: 30px;
	   font-family: 'Barlow-Medium';
	}
  }
}

.did-box{
  background: url('~@/assets/images/did_bg.png') center center;
  background-size: cover;
  .did{
	text-align: center;
	padding: 190px 0;
	max-width: 1200px;
	margin: 0 auto;
	.title{
	  font-size: 48px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 20px;
	  line-height: 30px;
	   font-family: 'Barlow-Medium';
	}
  }

}
  .multi-box{
	 background: url('~@/assets/images/multi_bg.png') center center;
     background-size: cover;
	 .multi{
		text-align: center;
		padding: 70px 0;
		max-width: 1200px;
		margin: 0 auto;
		.title{
		font-size: 48px;
		font-family: 'Nyala';
		color: #111;
		margin-bottom: 40px;
		}
	 }
  }
 .row-box{
  background: #fff;
   .row{
	 height: 80px;
	 font-size: 24px;
	 color: #111;
	 font-family: 'Barlow-Medium';
   }
   .row-active{
	 color: #fff;
	 background: #FF9900;
   }
 }
 .contribute-box{
   background: #fff;
   background-size: cover;
   .contribute{
        text-align: center;
		padding: 100px 0;
		max-width: 1200px;
		margin: 0 auto;
	  .title{
		font-size: 48px;
		font-family: 'Nyala';
		color: #111;
		margin-bottom: 40px;
		}
	   .desc{
		 font-size: 22px;
		 color: #666;
		 line-height: 32px;
		 margin-bottom: 60px;
	   }
   }
 }

.route-box{
  background: url('~@/assets/images/routeBg.png') center center;
  background-size: cover;
  .route{
	text-align: center;
	padding: 80px 0 30px;
	max-width: 1200px;
	margin: 0 auto;
	.title{
	  font-size: 48px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.img-box{
	  max-width: 1200px;
	  margin:  0 auto;
	  width: 100%;
	  img{
        display: block;
		width: 100%;
	  }
	}
  }
}
 @media screen and (max-width: 480px) {
  .banner{
   width: 100%;
   background: url('~@/assets/images/banner.png') center center #fff;
   background-size: 100% 100%;
   padding-bottom: 40px;
   .banner-img{
	//  position: relative;
	 display: block;
	//  width: 1920px;
	//  left: 50%;
	//  margin-left: -960px;
   }
}
.banner-text{
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 70px 0 300px;
  
  .title{
	color: #002233;
	font-size: 40px;
	font-family: 'Nyala';
  }
  .desc{
	font-size: 14px;
	color: #676767;
	font-family: 'Barlow-Medium';
	line-height: 24px;
  }
}


.global-info{
  max-width: 1200px;
  
  padding: 30px 15px;
  margin: 0 auto;
  margin-top: -250px;
  background: #fff;

  text-align: center;
  .title{
	font-size: 24px;
	font-family: 'Nyala';
	color: #111;
	margin-bottom: 10px;
  }
  .desc{
	color: #666;
	font-size: 14px;
	line-height: 22px;
	font-family: 'Barlow-Regular';
	  margin-bottom: 46px;
  }
}

.btn-box .el-button{
  width: 180px;
  height: 50px;
  border-radius: 33px;
  font-size: 16px;
  font-family: 'Barlow-Bold';
  line-height: 30px;
  .box{
	justify-content: center;
  }
  img{
	width: 18px;
	margin-right: 10px;
  }
}
 .telegram-box{
  background: url('~@/assets/images/telegram_bg.png') center center;
  background-size: cover;
  .telegram{
	text-align: center;
	padding: 95px 0;
	max-width: 1200px;
	margin: 0 auto;
	.desc-title{
	  color: #111;
	  font-size: 15px;
	   font-family: 'Barlow-Bold';
	}
	.title{
	  font-size: 24px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 14px;
	  line-height: 23px;
	   font-family: 'Barlow-Medium';
	}
  }
}
.dao-box{
  background: url('~@/assets/images/dao_bg.png') center center;
  background-size: cover;
  .dao{
	text-align: center;
	padding: 95px 0;
	max-width: 1200px;
	margin: 0 auto;
	.title{
	  font-size: 24px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 14px;
	  line-height: 24px;
	   font-family: 'Barlow-Medium';
	}
  }
}
.evmos-box{
  background: url('~@/assets/images/evmos_bg.png') center center;
  background-size: cover;
  .evmos{
	text-align: center;
	padding: 95px 0;
	max-width: 1200px;
	margin: 0 auto;
	.desc-title{
	  color: #111;
	  font-size: 15px;
	   font-family: 'Barlow-Bold';
	}
	.title{
	  font-size: 24px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 14px;
	  line-height: 24px;
	   font-family: 'Barlow-Medium';
	}
  }
}

.did-box{
  background: url('~@/assets/images/did_bg.png') center center;
  background-size: cover;
  .did{
	text-align: center;
	padding: 95px 0;
	max-width: 1200px;
	margin: 0 auto;
	.title{
	  font-size: 24px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.desc{
	  color: #333;
	  font-size: 14px;
	  line-height: 24px;
	   font-family: 'Barlow-Medium';
	}
  }

}
  .multi-box{
	 background: url('~@/assets/images/multi_bg.png') center center;
     background-size: cover;
	 .multi{
		text-align: center;
		padding: 35px 0;
		max-width: 1200px;
		margin: 0 auto;
		.title{
		font-size: 24px;
		font-family: 'Nyala';
		color: #111;
		margin-bottom: 20px;
		}
	 }
  }
 .row-box{
  background: #fff;
   .row{
	 height: 40px;
	 font-size: 12px;
	 color: #111;
	 font-family: 'Barlow-Medium';
   }
   .row-active{
	 color: #fff;
	 background: #FF9900;
   }
 }
 .contribute-box{
   background: #fff;
   background-size: cover;
   .contribute{
        text-align: center;
		padding: 50px 0;
		max-width: 1200px;
		margin: 0 auto;
	  .title{
		font-size: 24px;
		font-family: 'Nyala';
		color: #111;
		margin-bottom: 40px;
		}
	   .desc{
		 font-size: 14px;
		 color: #666;
		 line-height: 24px;
		 margin-bottom: 60px;
		 padding: 0 20px;
	   }
   }
 }
  .route-box{
  background: url('~@/assets/images/routeBg.png') center center;
  background-size: cover;
  .route{
	text-align: center;
	padding: 40px 0 20px;
	max-width: 1200px;
	margin: 0 auto;
	.title{
	  font-size: 24px;
	  font-family: 'Barlow-Bold';
	  color: #111;
	  margin-bottom: 20px;
	}
	.img-box{
	  max-width: 1200px;
	  margin:  0 auto;
	  width: 100%;
	  img{
        display: block;
		width: 100%;
	  }
	}
	
  }
}
}
</style>
