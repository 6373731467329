<template>
  <div class="verify-box">
  
   <main class="main main--verify">
     <div class="verify">
		<div class="title">{{$t('How to Verify Checksum')}}</div>
	 </div>
	 <div class="command-desc">{{$t('Use the instructions below to verify DST download integrity in a command line.')}}</div>
	 <div class="packge">
        <div class="packge-item">
			<div class="item-title">{{$t('Windows – Verify Checksum')}}</div>
			<div class="packge-row">
				<div class="row-title">{{$t('Run the following command:')}}</div>
				<div class="row-value">
				  <span>certutil -hashfile [location of file] SHA256</span>
				</div>
			</div>
			<div class="packge-row">
				<div class="row-title">{{$t('For example:')}}</div>
				<div class="row-value">
				  <span>certutil -hashfile C:\Users\%USERNAME%\Downloads\Setup-Win64.exe SHA256</span>
				</div>
			</div>
		</div>
		<div class="packge-item">
			<div class="item-title">{{$t('Linux – Verify Checksum')}}</div>
			<div class="packge-row">
				<div class="row-title">{{$t('Run the following command:')}}</div>
				<div class="row-value">
				  <span>shasum -a 256 [location of file]</span>
				</div>
			</div>
			<div class="packge-row">
				<div class="row-title">{{$t('For example:')}}</div>
				<div class="row-value">
				  <span>shasum -a 256 ~/Downloads/chia-blockchain_1.1.7_amd64.deb</span>
				</div>
			</div>
		</div>
	 </div>
	 <div class="go-download">
		{{$t('Return to the')}} <span @click="$router.push({name: 'Download'})">{{$t('Download')}}</span> {{$t('page.')}}
	 </div>
   </main>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  components: {
  },
  setup () {
     const data = reactive({
	   toUrl: (link) => {
		window.open(link)
	   }
     })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>

<style lang="scss" scoped>
.verify-box{
  max-width: 1200px;
  margin: 60px auto;
  background: #F4F4F4;
  box-sizing: border-box;
  padding: 40px 50px;
  .title{
	font-size: 48px;
	color: #111;
	font-family: 'Nyala';
	text-align: center;
	padding-bottom: 40px;
	border-bottom: 1px solid #ccc;
  }
  .packge-item{
	padding: 30px 0;
	border-bottom: 1px solid #ccc;
	.item-title{
		color: #111;
	  font-size: 32px;
	  font-family: 'Barlow-Bold';
	  margin-bottom: 20px;
	}
	.packge-row{
		margin: 10px 0;
	  .row-title{
			color: #333;
	  font-size: 22px;
	  font-family: 'Barlow-Medium';
	  margin-bottom: 10px;
	  }
	  .row-value{
				color: #333;
	  font-size: 20px;
	  font-family: 'Barlow-Medium';
		span{
		  background: #FFE6C0;
		  padding: 5px 10px;
		}
	  }
	}
  }
  .go-download{
	padding: 20px 0;
	font-size: 20px;
	color: #333;
	font-family: 'Barlow-Regular';
	span{
	  font-family: 'Barlow-Bold';
	  color: #0055FF;
	  cursor: pointer;
	}
  }
}
.command-desc{
      color: #666666;
	  font-size: 19px;
	  font-family: 'Barlow-Medium';
	  padding-top: 40px;
}
 @media screen and (max-width: 480px) {
	.verify-box{
  max-width: 1200px;
  margin: 40px auto;
  background: #F4F4F4;
  box-sizing: border-box;
  padding: 20px 25px;
  .title{
	font-size: 30px;
	color: #111;
	font-family: 'Nyala';
	text-align: center;
	padding-bottom: 20px;
	border-bottom: 1px solid #ccc;
  }
  .packge-item{
	padding: 20px 0;
	border-bottom: 1px solid #ccc;
	.item-title{
		color: #111;
	  font-size: 24px;
	  font-family: 'Barlow-Bold';
	  margin-bottom: 10px;
	}
	.packge-row{
		margin: 10px 0;
	  .row-title{
			color: #333;
	  font-size: 20px;
	  font-family: 'Barlow-Medium';
	  margin-bottom: 10px;
	  }
	  .row-value{
				color: #333;
	  font-size: 18px;
	  word-break: break-all;
	  font-family: 'Barlow-Medium';
		span{
		  background: #FFE6C0;
		  padding: 5px 10px;
		}
	  }
	}
  }
  .go-download{
	padding: 15px 0;
	font-size: 18px;
	color: #333;
	font-family: 'Barlow-Regular';
	span{
	  font-family: 'Barlow-Bold';
	  color: #0055FF;
	  cursor: pointer;
	}
  }
}
.command-desc{
      color: #666666;
	  font-size: 18px;
	  font-family: 'Barlow-Medium';
	  padding-top: 20px;
}
 }
</style>
